import React, { useEffect, useState } from "react"
import SearchBar from "../../components/SearchBar"
import "./styles.css"
import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"
import Select from "../../components/Select"
import { BsChevronDown } from "react-icons/bs"
import { MdWorkspacePremium } from "react-icons/md"

const options = [
	{ label: "All", value: 0 },
	{ label: "Normal User", value: 1 },
	{ label: "Business Owners", value: 2 },
	{ label: "Service Providers", value: 3 },
	{ label: "Guidance Providers", value: 4 }
]

const Users = () => {
	const [filterState, setFilterState] = useState()
	const [users, setUsers] = useState()
	const [searchState, setSearchState] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		;(async () => {
			setLoading(true)
			try {
				const response = await axios.get("/user/list")
				if (response.data)
					setUsers(
						response.data.map(i => ({
							...i,
							service_provider: Object?.values(i.services || {})?.some(_i => _i),
							guidance_provider: i.guide_links?.length > 0,
							title_text: [
								i.businesses?.map(i => i.title)?.join(", "),
								Object.keys(i?.services || {})
									?.filter(_i => i.services[_i])
									?.join(", ")
									?.replace(/_/g, " ")
									?.toUpperCase()
							]
								.filter(_i => _i)
								.join(" Business(s) & Services: ")
						}))
					)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	const filteredUsers = () => {
		if (searchState?.length > 3 && users?.length) {
			return users
				.map(i => {
					let score = 0
					if (i.name?.toLowerCase().includes(searchState?.toLowerCase())) score += 1
					if (i.businesses?.find(i => i.title?.toLowerCase()?.includes(searchState?.toLowerCase()))) score += 1
					return { ...i, score }
				})
				.filter(i => i.score)
				.sort((a, b) => b.score - a.score)
		}

		return users?.filter(
			i =>
				!filterState ||
				(filterState === 1
					? !i?.businesses?.[0] && !i.service_provider && !i.guidance_provider
					: filterState === 2
					? i?.businesses?.length
					: filterState === 3
					? i.service_provider
					: filterState === 4
					? i.guidance_provider
					: true)
		)
	}

	return (
		<div id="users">
			<div className="flex row">
				<TableTitle title={"Users"} />
				<div className="flex gap-m">
					<SearchBar
						value={searchState}
						onChange={e => setSearchState(e.target.value)}
						placeholder={"Search For Users"}
					/>
					<Select
						width="13rem"
						DropDownIcon={BsChevronDown}
						wrapperId={"city-filter"}
						options={options}
						onSelect={setFilterState}
						defaultSelection={options?.[0]?.value}
					/>
				</div>
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							<th className="left">Name</th>
							{filterState !== 1 && <th className="left">Type</th>}
							<th className="left">Mobile</th>
							<th className="left">Email</th>
							<th className="left">Address</th>
						</tr>
					</thead>
					<tbody>
						{filteredUsers()?.map((i, idx) => (
							<tr className="font-md">
								<td className="left">{idx + 1}.</td>
								<td className="left flex row gap-xs">
									{i.name || <em className="font-xs">N/A</em>}
									{i.premium && <MdWorkspacePremium fill={"var(--neon)"} className="font-m" />}
								</td>
								{filterState !== 1 && (
									<td className="left" title={i.title_text}>
										{[
											i.businesses?.length > 0 &&
												`${i.businesses?.length} Business${i.businesses?.length > 1 ? "es" : ""}`,
											(i.service_provider ? "Service" : "") +
												(i.guidance_provider ? `${i.service_provider ? " &" : ""} Guidance` : "") +
												(i.service_provider || i.guidance_provider ? " Provider" : "")
										]
											.filter(_i => _i)
											.join(", ") || <em className="font-xs">N/A</em>}
									</td>
								)}
								<td className="left">{i.phone_number}</td>
								<td className="left">{i.email || <em className="font-xs">N/A</em>}</td>
								<td className="left">{i.address || <em className="font-xs">N/A</em>}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
		</div>
	)
}

export default Users
