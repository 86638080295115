import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import "./styles.css"
import Spinner, { spinnerTypes } from "../../components/Spinner"

const Login = () => {
	const navigate = useNavigate()
	const [credentials, setCredentials] = useState({})
	const [loading, setLoading] = useState()

	const setData = e => setCredentials(prev => ({ ...prev, [e.target.name]: e.target.value }))
	const submit = async () => {
		try {
			setLoading(true)
			const response = await axios.post("/auth/login/admin", credentials)
			if (response.data) {
				localStorage.setItem("token", response.data.token)
				navigate("/")
				return
			}
		} catch (error) {
			console.log(error)
		}
		setLoading()
	}

	return (
		<div id="product-form" className="page-wrapper">
			<div className="container">
				<div>
					<h1 className="heading w-full text-center">Admin Login</h1>
				</div>
				<div>
					<div className="p-fields">
						<div style={{ margin: "auto" }}>
							<label htmlFor="email" className="font-s">
								Email
							</label>
							<input
								type="email"
								id="email"
								name="email"
								className="theme-input"
								defaultValue={""}
								value={credentials?.email}
								onChange={setData}
								required
							/>
						</div>
					</div>
					<div className="p-fields">
						<div style={{ margin: "auto" }}>
							<label htmlFor="password" className="font-s">
								Password
							</label>
							<input
								type="password"
								id="password"
								name="password"
								className="theme-input"
								defaultValue={""}
								value={credentials?.password}
								onChange={setData}
								required
							/>
						</div>
					</div>
				</div>
				<div>
					<button className="theme-btn primary flex form-submit relative" onClick={submit}>
						{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
						Login
					</button>
				</div>
			</div>
		</div>
	)
}

export default Login
