import React from "react"
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom"
import "./App.css"
import Dashboard from "./pages/dashboard/Page"
import Businesses from "./pages/businesses/Page"
import AdminLayout from "./layout/AdminLayout"
import CategoryForm from "./pages/categoryForm/Page"
import Categories from "./pages/categories/Page"
import Users from "./pages/users/Page"
import Login from "./pages/login/Page"
import Cities from "./pages/cities/Page"
import Orders from "./pages/orders/Page"
import Images from "./pages/gallery/Page"
import Settlement from "./pages/settlement/Page"
import Blogs from "./pages/blogs/Page"
import BlogForm from "./pages/blogForm/Page"
import Commission from "./pages/commission/Page"

const App = () => {
	return (
		<div id="App">
			<BrowserRouter>
				<AdminLayout>
					<Routes>
						<Route index element={<Navigate to="/dashboard" />} />
						<Route path="/login" element={<Login />} />
						<Route element={<ProtectedRoutes />}>
							<Route path="/dashboard" element={<Dashboard />} />
							<Route path="/businesses" element={<Businesses />} />
							<Route path="/users" element={<Users />} />
							<Route path="/cities" element={<Cities />} />
							<Route path="/commission" element={<Commission />} />
							<Route path="/gallery" element={<Images />} />
							<Route path="/orders" element={<Orders />} />
							<Route path="/settlement" element={<Settlement />} />
							<Route path="/blogs" element={<Blogs />} />
							<Route path="/blogs/form/:blogId?" element={<BlogForm />} />
							<Route path="/categories" element={<Categories />} />
							<Route path="/categories/form/:categoryId?" element={<CategoryForm />} />
						</Route>
					</Routes>
				</AdminLayout>
			</BrowserRouter>
		</div>
	)
}

const ProtectedRoutes = () => {
	const { pathname } = useLocation()
	const token = localStorage.getItem("token")
	return <>{token ? <Outlet /> : pathname !== "/login" ? <Navigate to={"/login"} /> : ""}</>
}

export default App
