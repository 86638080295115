import React, { useEffect, useState } from "react"
import "./styles.css"

import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import Prompt from "../../components/Prompt"
import TableTitle from "../../components/TableTitle"
import { RiDeleteBinLine } from "react-icons/ri"
import { FaCheck } from "react-icons/fa6"
import { TbSettings } from "react-icons/tb"
import { IoCloseOutline } from "react-icons/io5"
import { MdOutlineFileUpload } from "react-icons/md"
import { IoAlbumsOutline } from "react-icons/io5"
import { IoAdd } from "react-icons/io5"
import { IoClose } from "react-icons/io5"
import { BsViewList } from "react-icons/bs"
import { LiaUndoAltSolid } from "react-icons/lia"
import { IoMdArrowBack } from "react-icons/io"
import ImageSlider from "../../components/ImageSlider"

const Gallery = () => {
	const [albums, setAlbums] = useState()
	const [currAlbum, setCurrAlbum] = useState()
	const [imageSelection, setImageSelection] = useState([])
	const [removedImages, setRemovedImages] = useState([])
	const [viewAlbum, setViewAlbum] = useState()

	const [promptState, setPromptState] = useState()
	const [flags, setFlags] = useState({})

	const generateImageURL = album => ({
		...album,
		images: album.images?.map(i => ({
			...i,
			url: `${axios.defaults.baseURL}/${i.path}`
		}))
	})

	useEffect(() => {
		;(async () => {
			try {
				setFlags({ loading: true })
				const response = await axios.get("/admin/gallery")
				if (response.data) setAlbums(response.data?.map(generateImageURL))
			} catch (error) {
				alertPrompt({
					heading: "Failed To Fetch Images",
					message: "Images data could not be fetched due to an error.",
					error
				})
			}
			setFlags({})
		})()
	}, [])

	const alertPrompt = data =>
		setPromptState({
			...data,
			...(data?.error
				? {
						info: `${data?.error?.response?.statusText} (${data?.error?.response?.status}): ${
							data?.error?.response?.data?.error || data?.error?.message
						}`
				  }
				: {}),
			Actions: () => (
				<button className="theme-btn danger outlined" onClick={() => setPromptState()}>
					Okay
				</button>
			)
		})

	const convertImagesToURLs = files =>
		Promise.all(
			files?.map(
				file =>
					new Promise((resolve, reject) => {
						const reader = new FileReader()
						reader.onload = e =>
							resolve({
								name: file.name,
								url: e.target.result,
								file
							})
						reader.readAsDataURL(file)
					})
			)
		)

	const selectImages = async () => {
		const displayError = values =>
			setPromptState({
				heading: "Image Selection Error",
				...values,
				Actions: () => (
					<button className="theme-btn danger outlined" onClick={() => setPromptState()}>
						Okay
					</button>
				)
			})
		try {
			const result = await new Promise((resolve, reject) => {
				const input = document.createElement("input")
				input.type = "file"
				input.accept = "image/*"
				input.multiple = true
				input.addEventListener("change", e => {
					let result = {}
					const maxSize = 1200
					const files = Array.from(e.target.files)
					if (!files?.length) resolve(null)
					const oversizedImages = files?.filter(i => i.size / 1000 > maxSize)
					const processableFiles = files?.filter(i => i.size / 1000 <= maxSize)
					if (oversizedImages?.length) {
						result.error = {
							message: `${oversizedImages?.length} ${
								oversizedImages?.length > 1 ? "images are" : "image is"
							} oversized. Please select images with maximum file size of ${maxSize}KB.`,
							info: oversizedImages?.map(i => (
								<div>
									<span>
										{i.name} ({Math.round(i.size / 1000)}KB)
									</span>
								</div>
							))
						}
					}

					if (processableFiles?.length)
						convertImagesToURLs(processableFiles)?.then(urls => resolve({ ...result, urls }))
					else resolve(result)
				})
				input.click()
			})
			if (!result) return
			if (result?.error) displayError(result?.error)
			if (result?.urls?.length)
				setCurrAlbum(prev => ({
					...prev,
					images: (prev.images || []).concat(result?.urls)
				}))
		} catch (error) {
			displayError({ message: error?.message || error })
		}
	}

	const saveAlbum = async () => {
		setFlags({ loading: true })
		try {
			const form = new FormData()
			form.append("title", currAlbum.title)
			form.append("description", currAlbum.description)

			for (const image of removedImages) form.append("removed_images", image)
			for (const image of currAlbum.images?.filter(i => i.file)) form.append("image", image.file)

			const config = {
				method: "post",
				url: "/admin/gallery",
				headers: {
					"Authorization": `Bearer ${localStorage.getItem("token")}`,
					"Content-Type": "multipart/form-data"
				},
				data: form
			}
			if (currAlbum._id) {
				config.method = "put"
				config.url = config.url + `/${currAlbum?._id}`
			}

			const response = await axios(config)
			if (response.data) {
				const album = generateImageURL(response.data)
				if (currAlbum._id) setAlbums(prev => prev?.map(i => (i._id === currAlbum._id ? album : i)))
				else setAlbums(prev => [album].concat(prev))
				if (removedImages) setRemovedImages([])
				setCurrAlbum()
			}
		} catch (error) {
			console.log(error)
			alertPrompt({
				heading: "Failed To Upload Album",
				message: "Selected album could not be uploaded due to an error.",
				error
			})
		}
		setFlags({})
	}

	const deleteAlbum = async album_id => {
		setPromptState(prev => ({ ...prev, loading: true }))
		try {
			await axios.delete(`/admin/gallery/${album_id}`, {
				headers: {
					"Authorization": `Bearer ${localStorage.getItem("token")}`,
					"Content-Type": "application/json"
				}
			})

			setAlbums(prev => prev.filter(i => i._id !== album_id))
			setFlags({})
			setImageSelection([])
			setPromptState()
		} catch (error) {
			alertPrompt({
				heading: "Failed To Delete Images",
				message: "Images could not be deleted due to an error.",
				error
			})
		}
	}

	const deleteConfirmation = album => {
		setPromptState({
			heading: "Delete Album",
			message: `Selected album with ${album?.images?.length} image(s) will be deleted permanently.`,
			info: album?.title,
			Actions: () => (
				<>
					<button className="theme-btn grey" onClick={() => setPromptState()}>
						Cancel
					</button>
					<button className="theme-btn danger" onClick={() => deleteAlbum(album?._id)}>
						Delete Album
					</button>
				</>
			)
		})
	}

	const handleImageRemoving = () => {
		const localImages = imageSelection?.filter(i => currAlbum.images?.find(_i => _i.name === i)?.file)
		const uploadedImages = currAlbum?._id
			? imageSelection?.filter(i => !currAlbum.images?.find(_i => _i.name === i)?.file)
			: []

		if (localImages?.length)
			setCurrAlbum(prev => ({
				...prev,
				images: prev.images.filter(i => !localImages?.includes(i.name))
			}))

		if (uploadedImages?.length) setRemovedImages(prev => (prev || []).concat(uploadedImages))
		setImageSelection([])
	}

	return (
		<div id="gallery" className="container-w">
			<div
				className="flex row"
				style={{
					...(flags?.manage ? { position: "sticky", top: 0 } : {}),
					background: "white",
					zIndex: 1,
					padding: "20px"
				}}
			>
				<TableTitle title={"Gallery"} sticky={true} />
				<div className="flex categories-actions">
					<button className="theme-btn flex" onClick={() => setCurrAlbum({ visible: true })}>
						<IoAlbumsOutline className="font-l" /> Create Album
					</button>
				</div>
			</div>

			{currAlbum?.visible && (
				<div className="curr-album">
					<div className="flex row">
						<h2 className="subheading">{currAlbum?._id ? "Update" : "Create"} Album</h2>
						{currAlbum?._id && (
							<button
								className={`theme-btn flex danger outlined`}
								disabled={flags?.loading}
								onClick={() => deleteConfirmation(currAlbum)}
							>
								<RiDeleteBinLine style={{ fontSize: "18px" }} />
								<span>Delete Album</span>
							</button>
						)}
					</div>
					<div className="flex gap-m col start w-full">
						<input
							type="text"
							className="theme-input full"
							placeholder="Album Title"
							value={currAlbum?.title}
							onChange={e => setCurrAlbum(prev => ({ ...prev, title: e.target.value }))}
						/>
						<textarea
							rows={4}
							className="theme-input full"
							placeholder="Description"
							value={currAlbum?.description}
							onChange={e => setCurrAlbum(prev => ({ ...prev, description: e.target.value }))}
							style={{ whiteSpace: "break-spaces" }}
						/>
						<div className="images-section">
							{currAlbum?.images?.map((i, idx) => (
								<div key={i.name + idx} className="image-container relative">
									<img src={i.url} alt={i.name} />
									{removedImages?.includes(i.name) ? (
										<button
											type="button"
											className="undo-btn"
											onClick={() => setRemovedImages(prev => prev.filter(_i => _i !== i.name))}
										>
											<LiaUndoAltSolid />
										</button>
									) : (
										<button
											tabIndex={-1}
											className="selection"
											onClick={() =>
												setImageSelection(prev =>
													prev?.includes(i.name) ? prev?.filter(_i => _i !== i.name) : prev?.concat([i.name])
												)
											}
										>
											<div className={`mark-wrapper ${imageSelection?.includes(i.name) ? "checked" : ""}`}>
												{imageSelection?.includes(i.name) && <FaCheck className="mark" />}
											</div>
										</button>
									)}
								</div>
							))}
							<button type="button" className="image-container placeholder" onClick={selectImages}>
								<IoAdd />
							</button>
						</div>
						<div className="flex row gap-m w-full album-actions">
							<div className="flex gap-m">
								<button
									className={`theme-btn flex ${imageSelection?.length > 0 ? "danger" : ""}`}
									disabled={!imageSelection?.length}
									onClick={handleImageRemoving}
								>
									<IoClose style={{ fontSize: "18px" }} />
									<span>Remove Selection {imageSelection?.length > 0 ? `(${imageSelection?.length})` : ""}</span>
								</button>
							</div>
							<div className="flex gap-m">
								<button className="theme-btn flex grey" onClick={() => setCurrAlbum()}>
									<IoCloseOutline className="font-l" /> Discard
								</button>
								<button className="theme-btn flex primary" onClick={saveAlbum}>
									<MdOutlineFileUpload className="font-l" /> Save Album
								</button>
							</div>
						</div>
					</div>
				</div>
			)}

			<div>
				{viewAlbum?._id ? (
					<div className="album-view">
						<button className="flex back-btn" onClick={() => setViewAlbum()}>
							<IoMdArrowBack />
							<span>Back</span>
						</button>
						<h4 className="master-heading">{viewAlbum?.title}</h4>
						<p className="font-s">{viewAlbum?.description}</p>
						<h6 className="subheading">Images</h6>
						<div className="grid">
							{viewAlbum?.images?.map((i, idx) => (
								<button className="image-container" key={i.name} onClick={() => setFlags({ slider: idx })}>
									<img src={i.url} alt={i.name} />
								</button>
							))}
						</div>
					</div>
				) : (
					albums?.map(i => (
						<div key={i._id} className="album flex row-start">
							<div className="image-container cover-image relative">
								<img src={i.images[0]?.url} alt="album cover" />
								<IoAlbumsOutline className="font-xl album-icon" />
							</div>
							<div className="album-content">
								<div>
									<div>
										<span className="font-l text-ellipse" style={{ maxHeight: "4rem" }}>
											{i.title}
										</span>
									</div>
									<div>
										<span className="font-s text-ellipse">{i.description}</span>
									</div>
									<div>
										<span className="font-s">Images: {i.images.length}</span>
									</div>
								</div>
								<div className="actions-wrapper flex row-start gap-m">
									<button className="theme-btn flex" onClick={() => setViewAlbum(i)}>
										<BsViewList className="font-m" /> View Album
									</button>
									<button className="theme-btn flex" onClick={() => setCurrAlbum({ ...i, visible: true })}>
										<TbSettings className="font-m" /> Modify
									</button>
									<button className="theme-btn flex danger outlined" onClick={() => deleteConfirmation(i)}>
										<RiDeleteBinLine className="font-m" />
										<span>Delete Album</span>
									</button>
								</div>
							</div>
						</div>
					))
				)}
			</div>

			{typeof flags?.slider === "number" && (
				<ImageSlider
					close={() => setFlags({})}
					currentPosition={flags?.slider}
					srcs={viewAlbum?.images?.map(i => i.url)}
				/>
			)}
			{flags?.loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}
		</div>
	)
}

export default Gallery
