import React, { useEffect, useState } from "react"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"
import axios from "axios"

import { BsChevronDown, BsPatchCheck } from "react-icons/bs"
import { VscListFlat } from "react-icons/vsc"
import { IoCloseOutline } from "react-icons/io5"
import { BsFiletypePdf } from "react-icons/bs"

import Overlay from "../../layout/Overlay"
import Prompt from "../../components/Prompt"

import "./styles.css"
import "../orders/styles.css"
import Select from "../../components/Select"

const settleStatusOptions = [
	{ label: "Pending", value: 1 },
	{ label: "Settled", value: 2 }
]

const Settlement = () => {
	const [data, setData] = useState()
	const [flags, setFlags] = useState({ settleStatus: 1 })
	const [promptState, setPromptState] = useState()

	useEffect(() => {
		;(async () => {
			try {
				const response = await axios(`/settlement/${flags?.settleStatus === 1 ? "pending" : "settled"}`, {
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
				})

				setData(response.data)
			} catch (error) {}
			setTimeout(() => setFlags(prev => ({ ...prev, loading: false })), 200)
		})()
	}, [flags?.settleStatus])

	const handleSettlementStatus = async (data, utr_number) => {
		setFlags(prev => ({ ...prev, loading: true }))
		try {
			const latestOrderNumber = data.orders.sort(
				(a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			)[0].order_number

			const response = await axios({
				method: "post",
				url: "/settlement",
				data: { order_number: latestOrderNumber, business: data._id, utr_number },
				headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
			})

			if (response?.data?.success) setData(prev => prev.filter(i => i._id !== data._id))
		} catch (error) {
			console.log(error)
		}
		setPromptState(false)
		setFlags(prev => ({ ...prev, loading: false }))
	}

	const promptSettlementWarning = data =>
		setPromptState({
			heading: `Mark business orders settled`,
			message: (
				<span>
					All <b>{data.orders.length}</b> orders under business <b>{data.business}</b> will be marked{" "}
					<b>settled</b> for <b>₹{data.amount}</b>.
				</span>
			),
			info: (
				<>
					Please provide the <b>URT number</b> of the transaction made to the vendor below and confirm to continue.
				</>
			),
			onFormSubmit: target => handleSettlementStatus(data, target.utr_number.value),
			FormFields: () => (
				<input type="text" className="theme-input full" placeholder="UTR number" name="utr_number" required />
			),
			Actions: () => (
				<>
					<button type="button" className="theme-btn grey" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button className={`theme-btn primary`}>Confirm</button>
				</>
			)
		})

	return (
		<div id="categories">
			<div className="flex row">
				<TableTitle title={"Settlement"} />
				<div className="flex categories-actions">
					<Select
						width="13rem"
						label={"Settlement Status"}
						DropDownIcon={BsChevronDown}
						options={settleStatusOptions}
						onSelect={i => setFlags(prev => ({ ...prev, settleStatus: i }))}
						defaultSelection={settleStatusOptions[0].value}
					/>
				</div>
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							<th className="left">Business</th>
							{flags?.settleStatus === 2 && <th>UTR Number</th>}
							<th>Commission</th>
							<th>Settlement amount</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((i, idx) => (
							<tr key={i._id} className="font-md">
								<td className="left">{idx + 1}.</td>
								<td className="left">{i.business}</td>
								{flags?.settleStatus === 2 && <td>{i.utr_number}</td>}
								<td>₹{i.commission}</td>
								<td>₹{i.amount}</td>
								<td>
									<div className="flex gap-m row-end">
										<button
											className="theme-btn small grey"
											onClick={() => setFlags(prev => ({ ...prev, view: i._id }))}
										>
											<VscListFlat className="font-l" />
											<span>View Orders ({i.orders.length})</span>
										</button>
										{flags?.settleStatus === 1 && (
											<button className="theme-btn small primary" onClick={() => promptSettlementWarning(i)}>
												<BsPatchCheck className="font-l" />
												<span>Settle Now</span>
											</button>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{flags?.loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{flags?.view && (
				<OrdersList
					data={data?.find(i => i._id === flags?.view)}
					settle={promptSettlementWarning}
					close={() => setFlags(prev => ({ ...prev, view: null }))}
				/>
			)}
			{promptState && <Prompt {...promptState} />}
		</div>
	)
}

const OrdersList = ({ data, settle, close }) => (
	<Overlay>
		<div className="settlement-order-modal">
			<div className="flex row heading-wrapper">
				<div>
					<h2 className="heading">
						{data?.business} - ₹{data.amount}
					</h2>
					<div>
						<span className="font-s">UTR Number: {data?.utr_number}</span>
					</div>
					<span className="font-s">Settlement orders list ({data?.orders?.length})</span>
				</div>
				<div className="flex row-start gap-m">
					{!data?.utr_number && (
						<button className="theme-btn small primary flex" onClick={() => settle(data)}>
							<BsPatchCheck className="font-l" />
							<span>Settle Now</span>
						</button>
					)}
					<button className="theme-btn small outlined rounded flex" onClick={close}>
						<IoCloseOutline />
					</button>
				</div>
			</div>
			<div className="scrollable table-wrapper no-shadow">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							<th className="left">Invoice</th>
							<th className="left">Order number</th>
							<th>Grand total</th>
							<th>Commission %</th>
							<th>Commission</th>
							<th>Settlement amount</th>
						</tr>
					</thead>
					<tbody>
						{data?.orders?.map((i, idx) => (
							<tr key={i.order_number} className="font-md">
								<td className="left">{idx + 1}.</td>
								<td>
									<a
										href={axios.defaults.baseURL + `/order/invoice/vendor?order_number=${i.order_number}`}
										target="_blank"
										rel="noreferrer"
									>
										<BsFiletypePdf className="font-l" />
									</a>
								</td>
								<td className="left">{i.order_number}</td>
								<td>₹{i.grand_total}</td>
								<td>
									{i.settlement?.commission_percentage || "N/A"}
									{i.settlement?.commission_percentage ? "%" : ""}
								</td>
								<td>₹{i.settlement.commission}</td>
								<td>₹{i.settlement.amount}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	</Overlay>
)

export default Settlement
