import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { IoAddOutline } from "react-icons/io5"
import { BsArrowRight } from "react-icons/bs"
import { MdOutlineCategory } from "react-icons/md"
import { PiPlant, PiUsers } from "react-icons/pi"
import { RiBuilding2Line, RiFileList2Line } from "react-icons/ri"
import { HiOutlinePhotograph } from "react-icons/hi"
import { FaRegHandshake } from "react-icons/fa6"
import { PiNewspaper } from "react-icons/pi"
import { IoMdNotificationsOutline } from "react-icons/io"
import { TbDiscount } from "react-icons/tb"
import axios from "axios"
import "./styles.css"

const colors = ["#2828ac", "#DC143C", "#228B22", "#9B1B30", "#d4ca3b", "#6A5ACD", "#008080", "#4B0082", "#ef9b0f"]
const stacks = [
	{
		to: "/categories",
		Icon: MdOutlineCategory,
		title: "Categories",
		info: "Manage your categories & subcategories"
	},
	{
		to: "/commission",
		Icon: TbDiscount,
		title: "Commission",
		info: "Manage commission slabs for all the platform orders"
	},
	{
		to: "/gallery",

		Icon: HiOutlinePhotograph,
		title: "Gallery",
		info: "Manage & update images"
	},
	{
		to: "/blogs",

		Icon: PiNewspaper,
		title: "Blogs",
		info: "Create and manage sharanyu blogs"
	},
	{
		to: "/businesses",

		Icon: PiPlant,
		title: "Businesses",
		info: "Check currently active businesses"
	},
	{
		to: "/orders",

		Icon: RiFileList2Line,
		title: "Orders",
		info: "Keep track of the platform orders"
	},
	{
		to: "/settlement",

		Icon: FaRegHandshake,
		title: "Settlement",
		info: "Manage business orders' settlement"
	},
	{
		to: "/cities",
		Icon: RiBuilding2Line,
		title: "Cities",
		info: "Manage where you offer your services"
	},
	{ to: "/users", Icon: PiUsers, title: "Users", info: "See all type users on your platform" }
	// {
	// 	to: "https://sharanyu.com/",
	// 	color: "sharanyu",
	// 	Icon: MdOpenInNew,
	// 	title: "Sharanyu Web",
	// 	info: "Visit Sharanyu web presence"
	// }
]

const Dashboard = () => {
	const [unapprovedBusinesses, setUnapprovedBusinesses] = useState()

	useEffect(() => {
		const token = localStorage.getItem("token")
		if (token)
			(async () => {
				try {
					const response = await axios.get("/business/approval-pending", {
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`
						}
					})

					setUnapprovedBusinesses(response.data)
				} catch (error) {
					console.log(error)
				}
			})()
	}, [])

	return (
		<div id="dashboard" className="container-w">
			<h2 className="master-heading">Welcome To Sharanyu Admin</h2>
			<div id="top-content-container">
				{unapprovedBusinesses > 0 && (
					<div id="notifications-container" className="flex row-start gap-m">
						<IoMdNotificationsOutline className="subheading" />
						<span className="font-md">
							<b>{unapprovedBusinesses}</b> new businesses registered. Review and approve the requests{" "}
							<Link to={"/businesses"} className="link">
								here
							</Link>
							.
						</span>
					</div>
				)}
				<div id="add-category" className="font-l theme-font flex row">
					<span>
						<IoAddOutline id="add-label" />
						Let's Introduce A New Product Category
					</span>
					<Link to="/categories/form">
						<button className="flex">
							<BsArrowRight id="go" />
						</button>
					</Link>
				</div>
			</div>

			<h2 className="heading">Admin Stacks</h2>
			<div id="admin-stacks">
				{stacks?.map(({ to, Icon, title, info }, idx) => (
					<Link key={title} to={to}>
						<button className="stack">
							<div
								className="flex"
								style={{
									background: colors[idx]
								}}
							>
								<Icon />
							</div>
							<div>
								<span className="font-m">{title}</span>
								<span className="font-s">{info}</span>
							</div>
						</button>
					</Link>
				))}
			</div>
		</div>
	)
}

export default Dashboard
