import React, { useEffect, useState } from "react"
import { HiViewGridAdd } from "react-icons/hi"
import { BsChevronDown } from "react-icons/bs"
import { FaSortAmountUp } from "react-icons/fa"
import { PiNewspaper } from "react-icons/pi"

import { Link } from "react-router-dom"
import SearchBar from "../../components/SearchBar"
import Select from "../../components/Select"
import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"

const sortFields = [
	{
		value: "created_at",
		label: "Created At"
	},
	{
		value: "title",
		label: "Title"
	}
]

const Blogs = () => {
	const [blogs, setBlogs] = useState()
	const [searchState, setSearchState] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				let response = await axios.get("/blog/list")
				if (response.data)
					setBlogs(
						response.data.map(i => ({
							...i,
							cover: i?.cover ? `${axios.defaults.baseURL}/${i?.cover}` : null
						}))
					)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	const sort = selection => {
		setBlogs(prev =>
			prev
				?.toSorted((a, b) =>
					selection === "title"
						? a[selection]?.localeCompare(b[selection])
						: new Date(b[selection])?.getTime() - new Date(a[selection])?.getTime()
				)
				?.concat(prev?.filter(i => !i[selection]))
		)
	}

	const filteredBusinesses = () => {
		let collection = blogs
		if (blogs?.length)
			if (searchState?.length > 3) {
				const searchStr = searchState?.toLowerCase()
				collection = blogs.filter(i => i.title?.toLowerCase().includes(searchStr))
			}
		return collection
	}

	return (
		<div id="businesses" className="container-w">
			<div className="flex row">
				<TableTitle title={"Blogs"} />
				<Link to={"/blogs/form"}>
					<button className="theme-btn outlined flex">
						<HiViewGridAdd className="font-l" /> Write New Blog
					</button>
				</Link>
			</div>
			<div className="flex gap-m">
				<SearchBar value={searchState} onChange={e => setSearchState(e.target.value)} placeholder={"Search"} />
				<Select
					IdIcon={FaSortAmountUp}
					DropDownIcon={BsChevronDown}
					wrapperId={"nsort"}
					options={sortFields}
					onSelect={sort}
					hideInput={true}
					defaultSelection={sortFields[0].value}
				/>
			</div>
			<div className="ncards-wrapper">
				{filteredBusinesses()?.map(i => (
					<Link key={i._id} className="ncard pointer" to={`form/${i._id}`}>
						<div className="ncard-img">
							{i.cover ? (
								<img src={i.cover} alt="Blog Cover" />
							) : (
								<div className="flex h-full business-image-placeholder">
									<PiNewspaper />
								</div>
							)}
						</div>
						<div className="w-full">
							<div className="ncard-content">
								<h2 className="subheading text-ellipse nowrap">{i.title}</h2>
								<p className="font-md text-ellipse">{i.content?.replace(/&nbsp;/g, " ")}</p>
								<div>
									<span className="font-xs">{new Date(i.created_at).toDateString()}</span>
								</div>
							</div>
						</div>
					</Link>
				))}
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
		</div>
	)
}

export default Blogs
