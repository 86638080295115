import React, { useState } from "react"
import Prompt from "./Prompt"
import { Link, useNavigate } from "react-router-dom"

const Navbar = () => {
	const navigate = useNavigate()
	const [promptState, setPromptState] = useState()
	const logout = () => {
		localStorage.removeItem("token")
		setPromptState(false)
		navigate("/login")
	}

	return (
		<>
			<nav>
				<div>
					<Link to={"/dashboard"}>
						<img src="/images/logo.png" alt="Sharanyu" id="logo" />
					</Link>
				</div>
				<div>
					<div id="nav-links-wrapper"></div>
					<div id="nav-actions-wrapper">
						{localStorage.getItem("token") && <button onClick={() => setPromptState(1)}>Logout</button>}
					</div>
				</div>
			</nav>
			{promptState && (
				<Prompt
					heading="Logout"
					message={`Are you sure you want to log out of Sharanyu Admin?`}
					Actions={() => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={logout}>
								Logout
							</button>
						</>
					)}
				/>
			)}
		</>
	)
}

export default Navbar
