import React, { useEffect, useState } from "react"
import { PiPencilSimpleLineFill } from "react-icons/pi"
import { HiViewGridAdd } from "react-icons/hi"

import axios from "axios"
import SearchBar from "../../components/SearchBar"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import { RiDeleteBinLine } from "react-icons/ri"
import Prompt from "../../components/Prompt"
import TableTitle from "../../components/TableTitle"
import "./styles.css"

const Cities = () => {
	const [cities, setCities] = useState()
	const [searchState, setSearchState] = useState()
	const [loading, setLoading] = useState()
	const [promptState, setPromptState] = useState()
	const [editStage, setEditStage] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				const response = await axios.get("/city")
				if (response.data)
					setCities(
						response.data.map(i => ({
							...i,
							products: i?.businesses?.reduce((sum, _i) => sum + _i?.products, 0)
						}))
					)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	const filteredCategories = () => {
		let collection = cities
		if (cities?.length && searchState?.length)
			collection = cities.filter(i => i.name?.toLowerCase().includes(searchState?.toLowerCase()))
		return collection
	}

	const createCity = async () => {
		setEditStage(prev => ({ ...prev, loading: true }))
		try {
			const response = await axios.post(
				`/city`,
				{ name: editStage?.name },
				{ headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
			)
			if (response.data)
				setCities(prev => prev.concat([response.data]).toSorted((a, b) => a.name.localeCompare(b.name)))
		} catch (error) {}
		setEditStage()
	}

	const updateCity = async () => {
		setEditStage(prev => ({ ...prev, loading: true }))
		try {
			const response = await axios.put(`/city`, editStage, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})
			if (response.data) setCities(prev => prev.map(i => (i._id === response.data._id ? response.data : i)))
		} catch (error) {}
		setEditStage()
	}

	const deleteCity = async _id => {
		setPromptState(prev => ({ ...prev, loading: true }))
		try {
			await axios.delete(`/city`, {
				params: { id: _id },
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setCities(prev => prev.filter(i => i._id !== _id))
		} catch (error) {}
		setPromptState()
	}

	const deleteCityConfirmation = i => {
		setPromptState({
			heading: "Delete City",
			message: `Your city "${i?.name}" will be removed permanently.`,
			Actions: () => (
				<>
					<button className="theme-btn grey" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button className="theme-btn danger" onClick={() => deleteCity(i._id)}>
						Delete City
					</button>
				</>
			)
		})
	}

	return (
		<div id="categories">
			<div className="flex row">
				<TableTitle title={"Cities"} />
				<div className="flex categories-actions">
					<SearchBar
						value={searchState}
						onChange={e => setSearchState(e.target.value)}
						placeholder={"Search For City"}
					/>
					<button className="theme-btn outlined flex" onClick={() => setEditStage({ new: true })}>
						<HiViewGridAdd className="font-l" /> New City
					</button>
				</div>
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th>#</th>
							<th className="left w-full">City Name</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{editStage?.new && (
							<tr className="font-md">
								<td className="left"></td>
								<td className="left w-full" colSpan={2}>
									<div className="flex row-start gap-xl">
										<label className="theme-input w-full flex row">
											<input
												type="text"
												className="w-fit"
												value={editStage?.name}
												onChange={e => setEditStage(prev => ({ ...prev, name: e.target.value }))}
												placeholder="Enter city name here"
												disabled={editStage?.loading}
											/>
											{editStage?.loading && (
												<div>
													<Spinner type={spinnerTypes.default} />
												</div>
											)}
										</label>
										<div className="flex row-start gap-m">
											<button
												className="theme-btn w-m flex secondary"
												onClick={createCity}
												disabled={editStage?.loading}
											>
												Save
											</button>
											<button
												className="theme-btn w-m flex danger outlined"
												onClick={() => setEditStage()}
												disabled={editStage?.loading}
											>
												Cancel
											</button>
										</div>
									</div>
								</td>
							</tr>
						)}
						{filteredCategories()?.map((i, idx) => (
							<tr className="font-md">
								<td className="left">{idx + 1}.</td>
								<td className="left w-full" colSpan={editStage?._id === i._id ? 2 : 1}>
									{editStage?._id === i._id ? (
										<div className="flex row-start gap-xl">
											<label className="theme-input w-full flex row">
												<input
													type="text"
													className="w-fit"
													value={editStage?.name}
													onChange={e => setEditStage(prev => ({ ...prev, name: e.target.value }))}
													disabled={editStage?.loading}
												/>
												{editStage?.loading && (
													<div>
														<Spinner type={spinnerTypes.default} />
													</div>
												)}
											</label>
											<div className="flex row-start gap-m">
												<button
													className="theme-btn w-m flex secondary"
													onClick={updateCity}
													disabled={editStage?.loading}
												>
													Save
												</button>
												<button
													className="theme-btn w-m flex danger outlined"
													onClick={() => setEditStage()}
													disabled={editStage?.loading}
												>
													Cancel
												</button>
											</div>
										</div>
									) : (
										i.name
									)}
								</td>
								{editStage?._id !== i._id && (
									<td>
										<div className="flex gap-m">
											<button className="edit subheading" onClick={() => setEditStage(i)}>
												<PiPencilSimpleLineFill />
											</button>
											<button className="edit subheading" onClick={() => deleteCityConfirmation(i)}>
												<RiDeleteBinLine className="danger-font" />
											</button>
										</div>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}
		</div>
	)
}

export default Cities
