import React from "react"
import Overlay from "../layout/Overlay"
import Spinner, { spinnerTypes } from "./Spinner"

const Prompt = ({ Actions, heading, message, info, onFormSubmit, FormFields, loading }) => {
	return (
		<Overlay>
			<div className="prompt relative">
				{heading && <h2 className="subheading">{heading}</h2>}
				{message && <p className="font-md">{message}</p>}
				{info && (
					<div className="obj-info">
						<span className="font-xs">{info}</span>
					</div>
				)}
				<form
					onSubmit={e => {
						e.preventDefault()
						if (onFormSubmit) onFormSubmit(e.target)
					}}
				>
					{FormFields && <FormFields />}
					<div className="flex modal-actions">
						<Actions />
					</div>
				</form>
				{loading && <Spinner type={spinnerTypes.spinner} {...{ absolute: true }} />}
			</div>
		</Overlay>
	)
}

export default Prompt
