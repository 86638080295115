import React, { useEffect, useState } from "react"
import "./styles.css"
import { useNavigate, useParams } from "react-router-dom"
import { IoAddOutline, IoCloseOutline } from "react-icons/io5"
import { RiDeleteBinLine } from "react-icons/ri"
import { MdOutlineImage } from "react-icons/md"
import { GoCheckCircle } from "react-icons/go"
import { TbReload } from "react-icons/tb"
import Prompt from "../../components/Prompt"
import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"

const CategoryForm = () => {
	const navigate = useNavigate()
	const { categoryId } = useParams()
	const [categoryData, setCategoryData] = useState({})
	const [fileState, setFileState] = useState({})
	const [promptState, setPromptState] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		if (!categoryId) return
		;(async () => {
			setLoading("spinner")
			try {
				const response = await axios.get(`/category/${categoryId}`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				})
				if (response.data) setCategoryData(response.data)
			} catch (error) {
				console.log(error)
			}
			setLoading()
		})()
	}, [categoryId])

	const submit = async e => {
		try {
			setLoading(true)
			if (e) e.preventDefault()

			if (!categoryData?.subcategories?.length) {
				setPromptState({
					heading: "No sub-categories provided",
					message: `Atleat one sub-category must be provided to create a new category.`,
					Actions: () => (
						<button className="theme-btn primary" onClick={() => setPromptState(false)}>
							Okay
						</button>
					)
				})

				setLoading(false)
				return
			}
			const formData = new FormData(e.target)
			const response = await axios({
				method: categoryId ? "put" : "post",
				url: "/category/" + (categoryId || ""),
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				},
				data: formData
			})
			if (response.data) return navigate("/categories")
		} catch (error) {
			console.log(error)
		}
		setLoading()
	}

	const setData = (e, nested_field) =>
		setCategoryData(prev => ({
			...prev,
			...(nested_field
				? { [nested_field]: { ...(prev[nested_field] || {}), [e.target.name]: e.target.value } }
				: { ...prev, [e.target.name]: e.target.value })
		}))

	const deleteCategory = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			await axios({
				method: "delete",
				url: `/category/${categoryId}`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			navigate("/")
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const disableCategory = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			const response = await axios({
				method: "patch",
				url: `/category/${categoryId}/disable`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setCategoryData(response.data)
			setPromptState()
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const enableCategory = async () => {
		try {
			setPromptState(prev => ({ ...prev, loading: true }))
			const response = await axios({
				method: "patch",
				url: `/category/${categoryId}/enable`,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			setCategoryData(response.data)
			setPromptState()
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
		}
	}

	const checkCategoryOrder = async () => {
		setLoading(true)
		try {
			const count = (
				await axios.get(`/category/${categoryId}/order-count`, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				})
			)?.data?.orderCount

			if (count)
				setPromptState({
					heading: "Disable Category",
					message: `Your category "${categoryData?.name}" has ${count} order(s) in total and cannot be deleted. Would you like to disable it instead? After disabling, new products cannot be added under this category.`,
					Actions: () => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={disableCategory}>
								Disable Category
							</button>
						</>
					)
				})
			else
				setPromptState({
					heading: "Delete Category",
					message: `The selected category "${categoryData?.name}" & all it's sub-categories will be deleted permanently.`,
					Actions: () => (
						<>
							<button className="theme-btn grey" onClick={() => setPromptState(false)}>
								Cancel
							</button>
							<button className="theme-btn danger" onClick={deleteCategory}>
								Delete Category
							</button>
						</>
					)
				})
		} catch (error) {}
		setLoading(false)
	}

	const enableConfirmation = () => {
		setPromptState({
			heading: "Enable Category",
			message: `Activate this category now? Once enabled, it will be instantly accessible to businesses to add new products.`,
			Actions: () => (
				<>
					<button className="theme-btn danger outlined" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button className="theme-btn primary" onClick={enableCategory}>
						Enable Category
					</button>
				</>
			)
		})
	}

	const onFileSelect = e => {
		if (e.target.files[0].size > 1 * 1000 * 150) {
			setPromptState({
				heading: "Maximum file size exceeded",
				message: `Only upload file size of maximum 150KB`,
				Actions: () => (
					<button className="theme-btn outlined danger" onClick={() => setPromptState()}>
						Okay
					</button>
				)
			})
		} else setFileState(prev => ({ image: e.target.files?.[0] }))
	}

	return (
		<div id="product-form" className="page-wrapper container-w">
			<div className="container">
				<div>
					<TableTitle
						title={(categoryId ? "Update" : "Create") + " category"}
						info={categoryId && !categoryData?.disabled && "To modify any details, activate the category."}
					/>
					{categoryId &&
						(categoryData?.disabled ? (
							<button type="button" className="theme-btn primary flex" onClick={enableConfirmation}>
								<GoCheckCircle className="font-l" /> Enable Category
							</button>
						) : (
							<button className="theme-btn outlined danger flex" onClick={checkCategoryOrder}>
								<RiDeleteBinLine /> Delete This Category
							</button>
						))}
				</div>
				<form onSubmit={submit} className={categoryData?.disabled ? "disabled" : ""}>
					<div className="section">
						<div>
							<h2 className="font-l">Category details</h2>
							<div className="font-s">
								<span>Name, category, price and about</span>
							</div>
						</div>
						<div className="p-fields">
							<div>
								<label htmlFor="category-name" className="font-s">
									Category Name
								</label>
								<input
									type="text"
									id="category-name"
									className="theme-input"
									placeholder="e.g. Plants"
									name="name"
									defaultValue={""}
									value={categoryData?.name}
									onChange={setData}
									disabled={loading || categoryData?.disabled}
									required={true}
								/>
							</div>
							<div>
								<label htmlFor="index" className="font-s">
									Category Index
								</label>
								<input
									type="number"
									id="index"
									className="theme-input"
									name="index"
									defaultValue={""}
									value={categoryData?.index}
									onChange={setData}
									disabled={loading || categoryData?.disabled}
									required
								/>
							</div>
						</div>
						<div className="p-fields flex row">
							<h4 className="font-md">
								Sub Categories <span className="font-xs">({categoryData?.subcategories?.length || 0})</span>
							</h4>
							<button
								type="button"
								className="theme-btn icon flex rounded"
								onClick={() =>
									setCategoryData(prev => ({
										...prev,
										subcategories: (prev.subcategories || []).concat([
											{
												_id: prev?.subcategories?.length || 0,
												name: ""
											}
										])
									}))
								}
								disabled={loading || categoryData?.disabled}
							>
								<IoAddOutline className="font-l" />
							</button>
						</div>
						{categoryData?.subcategories?.[0] ? (
							<div className="p-fields wrap small">
								{categoryData?.subcategories?.map(({ _id, name, gst_rate }, idx) => (
									<div key={_id}>
										<div className="theme-input removable subcategory">
											<input
												type="text"
												placeholder="Sub category name"
												value={name}
												name={`subcategories[${idx}][name]`}
												disabled={loading || categoryData?.disabled}
												onChange={e =>
													setCategoryData(prev => ({
														...prev,
														subcategories: prev.subcategories.map(i =>
															i._id === _id ? { ...i, name: e.target.value } : i
														)
													}))
												}
												required={true}
											/>
											<input
												type="number"
												placeholder="GST rate %"
												value={gst_rate}
												name={`subcategories[${idx}][gst_rate]`}
												disabled={loading || categoryData?.disabled}
												onChange={e =>
													setCategoryData(prev => ({
														...prev,
														subcategories: prev.subcategories.map(i =>
															i._id === _id ? { ...i, gst_rate: e.target.value } : i
														)
													}))
												}
												required={true}
											/>
										</div>
										<button
											type="button"
											tabIndex={"-1"}
											className="remove font-l"
											onClick={() =>
												setCategoryData(prev => ({
													...prev,
													subcategories: prev.subcategories.filter(i => i._id !== _id)
												}))
											}
											disabled={loading || categoryData?.disabled}
										>
											<IoCloseOutline id="close-btn" />
										</button>
									</div>
								))}
							</div>
						) : (
							<div className="p-fields full">
								<div className="font-xs flex">
									<em>No Sub Categories Defined Yet.</em>
								</div>
							</div>
						)}
						<div className="p-fields full">
							<div>
								<h4 className="font-md">Category Image</h4>
								<div className="font-s">
									<span>Only upload square image, maximum size of 200kb & suggested resolution (64x64)</span>
								</div>
							</div>
						</div>
						<div className="p-fields full">
							<div className="theme-input">
								<label style={{ marginBottom: 0 }}>
									<input
										type="file"
										name="image"
										onChange={onFileSelect}
										disabled={loading || categoryData?.disabled}
										accept="image/*"
										hidden
									/>
									<div className="flex w-fit gap-m">
										<MdOutlineImage className="font-l" />
										{fileState?.image?.name ? (
											<span>{fileState?.image?.name}</span>
										) : categoryData?.image && fileState?.removeImage ? (
											<s>{categoryData?.image}</s>
										) : categoryData?.image ? (
											<span>{categoryData?.image}</span>
										) : (
											<span>No file selected</span>
										)}
									</div>
								</label>
								<button
									type="button"
									tabIndex={"-1"}
									className="remove font-l"
									onClick={() =>
										typeof categoryData?.image === "string"
											? setFileState(prev => ({ removeImage: !prev.removeImage }))
											: setFileState(prev => ({}))
									}
									disabled={loading || categoryData?.disabled}
								>
									{typeof categoryData?.image === "string" && !fileState?.image ? (
										!fileState?.removeImage ? (
											<RiDeleteBinLine className="danger-font" />
										) : (
											<TbReload />
										)
									) : fileState?.image ? (
										<IoCloseOutline />
									) : null}
								</button>
							</div>
						</div>
						{/* <div>
							<div>
								<small className="font-xs">Please ensure the provided product size is in inches</small>
							</div>
						</div> */}
					</div>
					<div>
						<button
							type="submit"
							className="theme-btn primary flex form-submit relative"
							disabled={loading || categoryData?.disabled}
						>
							{loading && <Spinner type={spinnerTypes.progressBar} {...{ transparent: 1, absolute: 1 }} />}
							{categoryId ? "Update" : "Submit"}
						</button>
					</div>
				</form>
			</div>
			{loading === "spinner" && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}
		</div>
	)
}

export default CategoryForm
