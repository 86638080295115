import React, { useEffect, useState } from "react"
import SearchBar from "../../components/SearchBar"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"
import Select from "../../components/Select"
import axios from "axios"
import { TiArrowUnsorted } from "react-icons/ti"
import { BsChevronDown } from "react-icons/bs"
import { IoCloseOutline } from "react-icons/io5"
import { TbFilterSearch } from "react-icons/tb"
import { BsFiletypePdf } from "react-icons/bs"
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti"
import { IoCheckmarkDoneOutline } from "react-icons/io5"
import { SlClock } from "react-icons/sl"

import Overlay from "../../layout/Overlay"
import "./styles.css"

const Orders = () => {
	const [orders, setOrders] = useState()
	const [searchState, setSearchState] = useState()
	const [flags, setFlags] = useState({})
	const [sortState, setSortState] = useState({})
	const [filtersData, setFiltersData] = useState()

	const cols = {
		"Order Number": { string: true },
		"Stage": { string: true },
		"Date": { string: true },
		"Business": { string: true },
		"Delivery Charges": { string: false, amount: true },
		"Grand Total": { string: false, amount: true },
		"Settlement": { string: false, amount: true },
		"Invoice Number": { string: true },
		"Placed By": { string: true },
		"Payment Method": { string: true },
		"Delivered To": { string: true },
		"Phone Number": { string: true },
		"City": { string: true },
		"Pincode": { string: false },
		"Address": {
			string: true,
			showTitle: true,
			style: { maxWidth: "500px", textOverflow: "ellipsis", overflow: "hidden" }
		},
		"Area or locality": {
			string: true,
			showTitle: true,
			style: { maxWidth: "500px", textOverflow: "ellipsis", overflow: "hidden" }
		}
	}

	const generateReport = data =>
		setOrders(
			data?.map(i => ({
				"Order Number": i.order_number,
				"Stage": i.stage,
				"Business": i.business,
				"Delivery Charges": i.delivery?.charges,
				"Grand Total": i.grand_total,
				"Settlement": i?.settlement?.commission,
				"Invoice Number": i.invoice_number,
				"Date": new Date(i.created_at).toDateString(),
				"Placed By": i.placed_by,
				"Payment Method": i.payment.cash ? "Cash" : "N/A",
				"Delivered To": i.delivery?.name,
				"Phone Number": i.delivery?.phone_number,
				"City": i.city,
				"Pincode": i.pincode,
				"Address": i.delivery?.address,
				"Area or locality": i.delivery?.address2,

				"settlementObj": i?.settlement
			})) || []
		)

	const fetchData = async (filterOptions = {}) => {
		try {
			setFiltersData(prev => ({
				...prev,
				selection: { ...filterOptions }
			}))
			setFlags({
				loading: true
			})

			if (filterOptions?.fromDate)
				filterOptions.fromDate = new Date(new Date(filterOptions?.fromDate).setHours(0, 0, 0, 0))

			if (filterOptions?.toDate)
				filterOptions.toDate = new Date(new Date(filterOptions?.toDate).setHours(23, 59, 59, 0))

			const response = await axios.post(
				"/order/list",
				{ filters: filterOptions },
				{ headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
			)

			generateReport(response.data?.orders)
		} catch (error) {
			console.log(error)
		}
		setFlags({
			loading: false
		})
	}

	useEffect(() => {
		;(async () => {
			try {
				let orderStages

				setFlags({ loading: true })
				const response = await axios({
					method: "post",
					url: "/order/list",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				})
				if (response.data) {
					generateReport(response.data?.orders)
					orderStages = [{ value: null, label: "All" }].concat(
						response.data.orderStages.map(i => ({
							label: i,
							value: i
						}))
					)
				}

				const [cities, businesses] = await Promise.all([axios.get("/city"), axios.get("/business/names")])
				setFiltersData({
					cities: [{ value: null, label: "All" }].concat(
						cities?.data?.map(i => ({
							label: i.name,
							value: i._id
						}))
					),
					businesses: [{ value: null, label: "All" }].concat(
						businesses?.data.map(i => ({
							label: i.title,
							value: i.id
						}))
					),
					orderStages
				})
			} catch (error) {}
			setFlags({ loading: false })
		})()
	}, [])

	const filteredCategories = () => {
		let collection = orders
		if (searchState?.length > 3 && orders?.length)
			return orders
				.map(i => {
					const txt = searchState?.toLowerCase()
					let score = 0

					for (const key of Object.keys(cols).filter(key => cols[key].string))
						if (i[key] && i[key]?.toString()?.toLowerCase().includes(txt)) score += 1

					return { ...i, score }
				})
				.filter(i => i.score)
				.sort((a, b) => b.score - a.score)

		return collection?.toSorted(
			(a, b) =>
				(typeof (a[sortState?.key] || b[sortState?.key]) === "string"
					? (a[sortState?.key] || "").localeCompare(b[sortState?.key] || "")
					: a[sortState?.key] - b[sortState?.key]) * sortState?.value
		)
	}

	return (
		<div id="categories">
			<div className="flex row">
				<TableTitle title={"Orders"} />
				<div className="flex categories-actions">
					<SearchBar
						value={searchState}
						onChange={e => setSearchState(e.target.value)}
						placeholder={"Search Order Details"}
					/>
					<button
						className="flex theme-btn outlined"
						onClick={() => setFlags(prev => ({ ...prev, filters: true }))}
					>
						<TbFilterSearch className="font-l" />
						<span className="font-md">Filters</span>
					</button>
				</div>
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							<th className="left">User</th>
							<th className="left">Vendor</th>
							{Object.keys(cols)?.map((i, idx) => (
								<th key={i + idx}>
									<div
										className="flex gap-xs pointer"
										style={cols[i].string ? { justifyContent: "flex-start" } : {}}
										onClick={() =>
											setSortState(prev => ({
												value: (prev?.key === i ? prev?.value || -1 : -1) * -1,
												key: i
											}))
										}
									>
										{i}
										{sortState?.key !== i ? (
											<TiArrowUnsorted />
										) : sortState?.value > 0 ? (
											<TiArrowSortedUp />
										) : sortState?.value < 0 ? (
											<TiArrowSortedDown />
										) : null}
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{filteredCategories()?.map((i, idx) => (
							<tr className="font-md">
								<td>{idx + 1}.</td>
								<td className="center">
									<a
										href={axios.defaults.baseURL + `/order/invoice/user?order_number=${i["Order Number"]}`}
										target="_blank"
										rel="noreferrer"
										style={{ display: "block", margin: "auto" }}
									>
										<BsFiletypePdf className="font-l" />
									</a>
								</td>
								<td className="center">
									<a
										href={axios.defaults.baseURL + `/order/invoice/vendor?order_number=${i["Order Number"]}`}
										target="_blank"
										rel="noreferrer"
										style={{ display: "block", margin: "auto" }}
									>
										<BsFiletypePdf className="font-l" />
									</a>
								</td>
								{Object.keys(cols)?.map(key => (
									<td
										className={cols[key].string ? "left" : ""}
										title={cols[key]?.showTitle ? i[key] : ""}
										style={cols[key]?.style}
									>
										{i[key] ? (
											key === "Stage" ? (
												<button className={`highlight-btn ${i[key]?.toLowerCase()}`}>{i[key]}</button>
											) : key === "Settlement" ? (
												<button
													className={`highlight-btn settlement ${
														Boolean(i?.settlementObj?.settled) ? "green" : "yellow"
													}`}
													title={
														Boolean(i?.settlementObj?.settled)
															? `Settled on ${
																	new Date(i.settlementObj.timestamp).toDateString() +
																	" at " +
																	new Date(i.settlementObj.timestamp).toLocaleTimeString()
															  }`
															: "Settlement Pending"
													}
												>
													<div>
														{Boolean(i?.settlementObj?.settled) ? (
															<IoCheckmarkDoneOutline />
														) : (
															<SlClock style={{ fontSize: 18 }} />
														)}
													</div>
													<span>{(cols[key].amount && i[key] ? "₹" : "") + i[key]}</span>
												</button>
											) : (
												(cols[key].amount && i[key] ? "₹" : "") + i[key]
											)
										) : (
											<i className="font-xs">N/A</i>
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{flags?.loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{flags?.filters && <Filters data={filtersData} close={() => setFlags()} search={fetchData} />}
		</div>
	)
}

const maxDate = [
	new Date().getFullYear(),
	(new Date().getMonth() + 1).toString().padStart(2, "0"),
	new Date().getDate().toString().padStart(2, "0")
].join("-")

const Filters = ({ data, close, search }) => {
	const [selection, setSelection] = useState({})
	useEffect(() => {
		if (data?.selection) setSelection(data?.selection)
	}, [data])

	return (
		<Overlay>
			<div className="order-filters-modal container-w">
				<div>
					<div>
						<div className="flex row heading-wrapper">
							<h2 className="heading">Filters</h2>
							<div className="flex row-start gap-l">
								<button className="theme-btn primary search" onClick={() => search(selection)}>
									Search
								</button>
								<button className="close" onClick={close}>
									<IoCloseOutline />
								</button>
							</div>
						</div>
						<div className="flex start row-start gap-xl content">
							<div className="flex col gap-m">
								<div className="flex col start">
									<span className="font-xs">From Date</span>
									<input
										type="date"
										className="theme-input"
										value={selection.fromDate}
										onChange={e => setSelection(prev => ({ ...prev, fromDate: e.target.value }))}
									/>
								</div>
								<div className="flex col start">
									<span className="font-xs">To Date</span>
									<input
										type="date"
										className="theme-input"
										max={maxDate}
										value={selection.toDate}
										onChange={e => setSelection(prev => ({ ...prev, toDate: e.target.value }))}
									/>
								</div>
								<div className="flex col start">
									<span className="font-xs">Settlement Status</span>
									<Select
										DropDownIcon={BsChevronDown}
										options={[
											{ label: "All", value: 0 },
											{ label: "Settled", value: 1 },
											{ label: "Unsettled", value: 2 }
										]}
										defaultSelection={selection?.settled}
										onSelect={i => setSelection(prev => ({ ...prev, settled: i }))}
										placeholder={"All"}
									/>{" "}
								</div>
							</div>
							<div className="flex col gap-m">
								<div className="flex col start">
									<span className="font-xs">City</span>
									<Select
										DropDownIcon={BsChevronDown}
										options={data?.cities}
										defaultSelection={selection?.city}
										onSelect={i => setSelection(prev => ({ ...prev, city: i }))}
										placeholder={"All"}
										multiselect={true}
									/>{" "}
								</div>
								<div className="flex col start">
									<span className="font-xs">Business</span>
									<Select
										DropDownIcon={BsChevronDown}
										options={data?.businesses}
										defaultSelection={selection?.business}
										onSelect={i => setSelection(prev => ({ ...prev, business: i }))}
										placeholder={"All"}
										dropdownPosition={"left"}
										multiselect={true}
									/>
								</div>
								<div className="flex col start">
									<span className="font-xs">Status</span>
									<Select
										DropDownIcon={BsChevronDown}
										wrapperId={"city-filter"}
										options={data?.orderStages}
										defaultSelection={selection?.orderStage}
										onSelect={i => setSelection(prev => ({ ...prev, stage: i }))}
										placeholder={"All"}
										dropdownPosition={"left mid"}
									/>{" "}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Overlay>
	)
}

export default Orders
