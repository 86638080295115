import React from "react"
import { PiPathFill } from "react-icons/pi"
import { Link } from "react-router-dom"

const TableTitle = ({ title, info }) => {
	return (
		<div className="page-title-wrapper flex col start gap-s">
			<div className="flex col start">
				<h2 className="master-heading">{title}</h2>
				{info && <small className="font-xs">{info}</small>}
			</div>
			<div className="flex gap-m">
				<PiPathFill />
				<div className="flex">
					{["dashboard"]
						.concat(
							window.location.pathname
								.split("/")
								.filter(i => i)
								.slice(0, -1)
						)
						.map((i, idx, arr) => (
							<>
								<Link className="flex font-xs gap-xs underline" to={`/${i}`}>
									{i}
								</Link>
								{idx + 1 < arr.length && <span className="font-xs">/</span>}
							</>
						))}
				</div>
			</div>
		</div>
	)
}

export default TableTitle
