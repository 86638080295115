import React, { useEffect, useState } from "react"
import "./styles.css"
import { HiOutlineLocationMarker } from "react-icons/hi"
import { BsChevronDown } from "react-icons/bs"
import { TbAward, TbAwardFilled } from "react-icons/tb"
import { AiOutlineStop } from "react-icons/ai"
import { IoCloseCircle } from "react-icons/io5"

import SearchBar from "../../components/SearchBar"
import Select from "../../components/Select"
import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"

import { TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted } from "react-icons/ti"
import { IoCheckmarkDoneOutline } from "react-icons/io5"
import { SlClock } from "react-icons/sl"
import Prompt from "../../components/Prompt"
import Overlay from "../../layout/Overlay"
import ImageSlider from "../../components/ImageSlider"

const cols = {
	"Id": { string: true },
	"Title": { string: true },

	"Active By Admin": { string: true },
	"Active By Vendor": { string: true },

	"Vendor": { string: true },
	"Images": { sort: false },
	"PAN": { string: true },
	"GSTIN": { string: true },
	"Primary Number": { string: true },
	"Secondary Number": { string: true },

	"Featured": { number: true },
	"City": { string: true },
	"Complete Address": { string: true },
	"Locality": { string: true },
	"Geo-Location": { string: true },

	"Discounts": { sort: false },
	"Business Natures": { string: true },
	"Product types": { string: true },
	"Min order value": { number: true, right: true },
	"Delivery timeframe": { string: true },
	"Delivery all over india": { string: true }
	// "Delivery charges (%)": { number: true },
	// "Extra devlivery charges (%)": { number: true }
}

const Businesses = () => {
	const [promptState, setPromptState] = useState()
	const [businesses, setBusinesses] = useState()
	const [searchState, setSearchState] = useState()
	const [cityFilter, setCityFilter] = useState()
	const [cities, setCities] = useState()
	const [loading, setLoading] = useState()
	const [sortState, setSortState] = useState()
	const [featureStatusUpdating, setFeatureStatusUpdating] = useState()
	const [businessImages, setBusinessImages] = useState()
	const [discountTableState, setDiscountTableState] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				let cityResponse = await axios.get("/city")
				if (cityResponse.data)
					setCities(
						[{ value: null, label: "All" }].concat(
							cityResponse.data?.map(i => ({
								value: i._id,
								label: i.name
							}))
						)
					)

				const businessResponse = await axios.get("/business/list")
				setBusinesses(businessResponse.data)
			} catch (error) {
				console.error(error)
			}
			setLoading()
		})()
	}, [])

	const displayPrompt = i => {
		const status = i.active_status?.by_admin <= 0 ? 1 : 0
		const status_label =
			i.active_status?.by_admin === -1 ? "Approve" : i.active_status?.by_admin === 0 ? "Enable" : "Disable"

		const infos = [
			"Once the business is approved, all the actions are immidiately accessible.",
			"All business actions will be active immediately once the business is marked enabled.",
			"Once the business is marked enabled, all further business activities will be revoked immidiately."
		]

		setPromptState({
			heading: `${status_label} Business`,
			message: (
				<>
					Are you sure you want to <b>{status_label}</b> business <b>{i.title}</b>?
				</>
			),
			info: infos[i.active_status?.by_admin + 1],
			Actions: () => (
				<>
					<button className="theme-btn grey" onClick={() => setPromptState(false)}>
						Cancel
					</button>
					<button
						className={`theme-btn ${status ? "primary" : "danger"}`}
						onClick={() => updateStatus(i.id, status)}
					>
						{status_label}
					</button>
				</>
			)
		})
	}

	const updateFeatured = async (id, featured) => {
		setFeatureStatusUpdating(prev => (prev || []).concat([id]))
		try {
			await axios.patch(
				`/business/${id}/featured`,
				{ featured },
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				}
			)
		} catch (error) {
			console.error(error)
		}
		setFeatureStatusUpdating(prev => prev.filter(i => i !== id))
	}

	const handleFeatured = i => {
		setBusinesses(prev => {
			const featuredCount = prev?.filter(_i => i.city === _i.city && _i.featured)?.map(_i => _i.featured)
			if (featuredCount?.length === 4 && !i.featured) return prev
			const maxCount = featuredCount?.length ? Math.max(...featuredCount) : 0
			let vacantPosition = null
			for (let index = 1; index <= maxCount; index++)
				if (!featuredCount?.includes(index)) {
					vacantPosition = index
					break
				}

			return prev.map(_i => {
				if (_i.id === i.id) {
					const featured = _i.featured ? null : vacantPosition || maxCount + 1
					updateFeatured(i.id, featured)
					return { ..._i, featured }
				} else return _i
			})
		})
	}

	const updateStatus = async (id, status) => {
		setPromptState(prev => ({ ...prev, loading: true }))
		try {
			const response = await axios({
				method: "patch",
				url: "/business/admin-status",
				data: {
					id,
					status
				},
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})

			if (response.data.success)
				setBusinesses(prev =>
					prev?.map(i =>
						i.id === id
							? {
									...i,
									active_status: {
										...i.active_status,
										by_admin: status
									}
							  }
							: i
					)
				)
			setPromptState()
		} catch (error) {
			setPromptState(prev => ({ ...prev, loading: false }))
			console.error(error)
		}
	}

	return (
		<div id="businesses">
			<TableTitle title={"Businesses"} />
			<div className="flex gap-m">
				<SearchBar
					value={searchState}
					onChange={e => setSearchState(e.target.value)}
					placeholder={"Search For Business Titles, Owners, Categories & Address"}
				/>
				<Select
					IdIcon={HiOutlineLocationMarker}
					DropDownIcon={BsChevronDown}
					wrapperId={"city-filter"}
					options={cities}
					onSelect={setCityFilter}
					defaultSelection={cities?.[0]?.value}
				/>
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							{Object.keys(cols)?.map((i, idx) => (
								<th key={i + idx} className={cols[i].string ? "left" : ""}>
									<div
										className="flex gap-xs pointer"
										onClick={() =>
											setSortState(prev => ({
												value: (prev?.key === i ? prev?.value || -1 : -1) * -1,
												key: i
											}))
										}
									>
										{i}
										{cols[i].sort === false ? null : sortState?.key !== i ? (
											<TiArrowUnsorted />
										) : sortState?.value > 0 ? (
											<TiArrowSortedUp />
										) : sortState?.value < 0 ? (
											<TiArrowSortedDown />
										) : null}
									</div>
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{businesses
							?.filter(i => !cityFilter || cityFilter === i.city)
							?.map((i, idx) => (
								<tr className="font-md">
									<td className="left">{idx + 1}.</td>
									<td className="left">{i.id || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.title || <i className="font-xs">N/A</i>}</td>
									<td className="left">
										<button
											className={`highlight-btn settlement ${
												i.active_status?.by_admin === -1 ? "yellow" : i.active_status?.by_admin ? "green" : "red"
											}`}
											onClick={() => displayPrompt(i)}
										>
											<div>
												{i?.active_status?.by_admin === -1 ? (
													<SlClock style={{ fontSize: 18, strokeWidth: "1.5px" }} />
												) : i?.active_status?.by_admin ? (
													<IoCheckmarkDoneOutline />
												) : (
													<AiOutlineStop />
												)}
											</div>
											<span className="featured">
												{i.active_status?.by_admin === -1
													? "Pending"
													: i.active_status?.by_admin
													? "Enabled"
													: "Disabled"}
											</span>
										</button>
									</td>
									<td className="left">{i.active_status?.by_vendor ? "Enabled" : "Disabled"}</td>
									<td className="left">{i.vendor || <i className="font-xs">N/A</i>}</td>
									<td className="left">
										<button
											className="highlight-btn default"
											onClick={() =>
												setBusinessImages({
													visible: true,
													business: i.title,
													cover_images: i.cover_images,
													images: i.images
												})
											}
										>
											Show Images
										</button>
									</td>
									<td className="left">{i.pan || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.gstin || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.contact_number.primary || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.contact_number.secondary || <i className="font-xs">N/A</i>}</td>
									<td className="left">
										{featureStatusUpdating?.includes(i.id) ? (
											<button className="highlight-btn btn-loader">
												<Spinner type={spinnerTypes.default} transparent={true} />
											</button>
										) : (
											<button
												className={`highlight-btn settlement ${Boolean(i.featured) ? "green" : "yellow"}`}
												onClick={() => handleFeatured(i)}
											>
												<div>
													{Boolean(i?.featured) ? (
														<TbAwardFilled />
													) : (
														<TbAward style={{ fontSize: 18, strokeWidth: "1.5px" }} />
													)}
												</div>
												<span className="featured">{i.featured || "Mark"}</span>
											</button>
										)}
									</td>
									<td className="left">
										{cities?.find(_i => _i.value === i.city)?.label || <i className="font-xs">N/A</i>}
									</td>
									<td className="left">{i.complete_address || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.locality || <i className="font-xs">N/A</i>}</td>
									<td className="left">
										{i.geolocation?.lat ? (
											`${i.geolocation?.lat}, ${i.geolocation?.lng}`
										) : (
											<i className="font-xs">N/A</i>
										)}
									</td>
									<td className="left">
										<button
											className="highlight-btn default"
											onClick={() =>
												setDiscountTableState({
													business: i.title,
													discounts: i.discounts
												})
											}
										>
											Show Discounts Table
										</button>
									</td>
									<td className="left">{i.business_natures?.join(", ") || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.product_types?.join(", ") || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.min_order_value || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.delivery_timeframe || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.deliver_all_over_india ? "Yes" : "No"}</td>
									{/* <td className="left">{i.delivery_charge?.base_percentage || <i className="font-xs">N/A</i>}</td>
									<td className="left">{i.delivery_charge?.extra_percentage || <i className="font-xs">N/A</i>}</td> */}
								</tr>
							))}
					</tbody>
				</table>
			</div>

			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
			{promptState && <Prompt {...promptState} />}

			{businessImages?.slider && (
				<ImageSlider
					close={() => setBusinessImages(prev => ({ ...prev, slider: null }))}
					currentPosition={businessImages?.slider?.currentPosition}
					srcs={businessImages?.[businessImages?.slider?.field]?.map(i => `${axios.defaults.baseURL}/${i.path}`)}
				/>
			)}

			{discountTableState && (
				<Overlay>
					<div
						id="business-images"
						className="container-w relative"
						style={{ maxWidth: "40%", overflow: "hidden" }}
					>
						<button className="close" onClick={() => setDiscountTableState()}>
							<IoCloseCircle />
						</button>
						<h2 className="subheading">{discountTableState?.business}'s discounts</h2>
						<div>
							<div id="modal-business-images" style={{ maxHeight: "calc(80vh - 3rem)", overflow: "auto" }}>
								<table>
									<thead>
										<tr>
											<th className="left">Minimum amount</th>
											<th className="left">Discount</th>
										</tr>
									</thead>
									<tbody>
										{discountTableState?.discounts?.map((i, idx) => (
											<tr>
												<td className="left" style={{ width: "50%" }}>
													₹{i.min_amount}
												</td>
												<td className="left" style={{ width: "50%" }}>
													{i.discount}%
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Overlay>
			)}

			{businessImages?.visible && (
				<Overlay>
					<div id="business-images" className="container-w relative">
						<button className="close" onClick={() => setBusinessImages()}>
							<IoCloseCircle />
						</button>
						<h2 className="subheading">{businessImages?.business}'s Covers</h2>
						<div>
							<div id="business-cover">
								{businessImages?.cover_images?.map((i, idx) => (
									<img
										src={`${axios.defaults.baseURL}/${i.path}`}
										alt={i.name}
										onClick={() =>
											setBusinessImages(prev => ({
												...prev,
												slider: {
													currentPosition: idx,
													field: "cover_images"
												}
											}))
										}
									/>
								))}
							</div>
						</div>
						<h2 className="subheading">{businessImages?.business}'s Images</h2>
						<div>
							<div id="modal-business-images">
								{businessImages?.images?.map((i, idx) => (
									<img
										src={`${axios.defaults.baseURL}/${i.path}`}
										alt={i.name}
										onClick={() =>
											setBusinessImages(prev => ({
												...prev,
												slider: {
													currentPosition: idx,
													field: "images"
												}
											}))
										}
									/>
								))}
							</div>
						</div>
					</div>
				</Overlay>
			)}
		</div>
	)
}

export default Businesses
