import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { PiPencilSimpleLineFill } from "react-icons/pi"
import { HiViewGridAdd } from "react-icons/hi"
import axios from "axios"
import SearchBar from "../../components/SearchBar"
import "./styles.css"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"

const Categories = () => {
	const [categories, setCategories] = useState()
	const [searchState, setSearchState] = useState()
	const [loading, setLoading] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				const response = await axios.get("/category/list")
				if (response.data)
					setCategories(
						response.data.map(i => ({
							...i,
							products: i?.businesses?.reduce((sum, _i) => sum + _i?.products, 0)
						}))
					)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	const filteredCategories = () => {
		let collection = categories
		if (searchState?.length > 3 && categories?.length) {
			collection = categories
				.map(i => {
					let score = 0
					if (i.name?.toLowerCase().includes(searchState?.toLowerCase())) score += 1
					if (i.subcategories.join(" ")?.toLowerCase().includes(searchState?.toLowerCase())) score += 0.5
					return { ...i, score }
				})
				.filter(i => i.score)
				.sort((a, b) => b.score - a.score)
		}
		return collection
	}

	return (
		<div id="categories">
			<div className="flex row">
				<TableTitle title={"Categories"} />
				<div className="flex categories-actions">
					<SearchBar
						value={searchState}
						onChange={e => setSearchState(e.target.value)}
						placeholder={"Search For Categories & Sub Categories"}
					/>
					<Link to={"/categories/form"}>
						<button className="theme-btn outlined flex">
							<HiViewGridAdd className="font-l" /> New Category
						</button>
					</Link>
				</div>
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							<th className="left">Category Name</th>
							<th>Sub Categories</th>
							<th>Used By</th>
							<th>Products</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredCategories()?.map((i, idx) => (
							<tr className="font-md">
								<td className="left">{i.index}.</td>
								<td className="left">{i.name}</td>
								<td>{i?.subcategories?.length}</td>
								<td>{i?.businesses?.length}</td>
								<td>{i?.products}</td>
								<td>
									<Link to={`/categories/form/${i._id}`}>
										<button className="edit subheading">
											<PiPencilSimpleLineFill />
										</button>
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
		</div>
	)
}

export default Categories
