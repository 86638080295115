import React, { useEffect, useState } from "react"
import BlurWatcher from "./BlurWatcher"

export const CheckBox = () => (
	<svg fill="var(--neon)" width="28px" height="28px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
		<path d="M400,48H112a64.07,64.07,0,0,0-64,64V400a64.07,64.07,0,0,0,64,64H400a64.07,64.07,0,0,0,64-64V112A64.07,64.07,0,0,0,400,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z"></path>
	</svg>
)
export const CheckBoxMinus = () => (
	<svg fill="var(--neon)" width="28px" height="28px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
		<defs></defs>
		<path d="M400,48H112a64.07,64.07,0,0,0-64,64V400a64.07,64.07,0,0,0,64,64H400a64.07,64.07,0,0,0,64-64V112A64.07,64.07,0,0,0,400,48Z"></path>
		<path
			d="M 353.87 274.286 L 161.27 274.286 C 159.613 274.286 158.27 273.598 158.27 272.75 L 158.27 239.711 C 157.582 238.581 159.539 237.494 161.792 237.753 C 162.353 237.818 162.866 237.964 163.27 238.174 L 350.87 238.174 C 352.527 238.174 353.87 238.862 353.87 239.711 L 353.87 272.75 C 353.87 273.598 352.527 274.286 350.87 274.286 L 353.87 274.286 Z"
			fill="white"
		></path>
	</svg>
)
export const CheckBoxEmpty = () => (
	<svg fill="var(--border)" width="28px" height="28px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
		<path d="M400,48H112a64.07,64.07,0,0,0-64,64V400a64.07,64.07,0,0,0,64,64H400a64.07,64.07,0,0,0,64-64V112A64.07,64.07,0,0,0,400,48Z"></path>
	</svg>
)

const Select = ({
	options,
	onSelect,
	defaultSelection,
	wrapperId,
	IdIcon,
	DropDownIcon,
	placeholder,
	disabled,
	multiselect,
	customInputProps,
	emptyVisible = true,
	filterOptions = true,
	dropdownPosition = "",
	width,
	label: inputLabel
}) => {
	const [dropDownState, setDropDownState] = useState({ selected: defaultSelection, dropdown: false })
	useEffect(() => {
		setDropDownState(prev => ({ ...prev, selected: defaultSelection }))
	}, [defaultSelection])

	const label =
		(multiselect
			? options
					?.filter(i => dropDownState.selected?.includes(i.value))
					?.map(i => i?.label)
					?.join(", ")
			: options?.find(i => i.value === dropDownState.selected)?.label) || placeholder
	const filteredOptions = filterOptions
		? options?.filter(
				i =>
					!i.disabled &&
					(!dropDownState?.filter?.trim() || i?.label?.toLowerCase()?.includes(dropDownState.filter.toLowerCase()))
		  )
		: options

	console.log(defaultSelection, dropDownState)

	const handleSelect = (e, isReset, value) => {
		e.stopPropagation()
		if (isReset) {
			setDropDownState(prev => ({
				...prev,
				selected: multiselect ? [] : value
			}))
			onSelect?.(multiselect ? [] : value)
		} else
			setDropDownState(prev => {
				const selected = multiselect
					? (prev?.selected || [])?.includes(value)
						? (prev?.selected || [])?.filter(_i => _i !== value)
						: (prev?.selected || [])?.concat([value])
					: value
				onSelect?.(selected)
				return { selected, dropdown: multiselect }
			})
	}

	return (
		<div>
			{inputLabel && (
				<div className="select-input-label">
					<span className="font-xs">{inputLabel}</span>
				</div>
			)}
			<BlurWatcher
				id={wrapperId}
				className="select"
				onClick={() => !disabled && setDropDownState(i => ({ ...i, dropdown: !i.dropdown }))}
				onClickOutside={() => setDropDownState(i => ({ ...i, filter: null, dropdown: false }))}
				style={{
					...(width ? { width } : {}),
					...(disabled ? { cursor: "not-allowed" } : {})
				}}
			>
				<div className="flex">
					{IdIcon && <IdIcon />}
					{dropDownState.dropdown ? (
						<input
							type="text"
							value={dropDownState.filter || ""}
							onChange={e => setDropDownState(i => ({ ...i, filter: e.target.value }))}
							autoFocus={true}
							placeholder={label}
							disabled={disabled}
							{...(customInputProps || {})}
						/>
					) : (
						<span style={{ pointerEvents: "none" }} className={!dropDownState.selected ? "no-value" : ""}>
							{label}
						</span>
					)}
					{DropDownIcon && <DropDownIcon />}
				</div>
				{dropDownState?.dropdown && (emptyVisible || filteredOptions?.length > 0) && (
					<div className={"select-dropdown " + dropdownPosition}>
						{filteredOptions?.[0] ? (
							filteredOptions?.map(({ label, value }) => {
								const isReset = ["none", "all"].includes(label.toLowerCase())
								return (
									<button
										type="button"
										className="flex row-start gap-m"
										onClick={e => handleSelect(e, isReset, value)}
									>
										{multiselect &&
											!isReset &&
											(dropDownState?.selected?.includes(value) ? <CheckBox /> : <CheckBoxEmpty />)}
										{label}
									</button>
								)
							})
						) : (
							<em className="font-xs" style={{ textAlign: "center" }}>
								No Match Found
							</em>
						)}
					</div>
				)}
			</BlurWatcher>
		</div>
	)
}

export default Select
