import React from "react"
import { useRef } from "react"

const BlurWatcher = ({ children, onClickOutside, ...props }) => {
	const ref = useRef()
	return (
		<div
			tabIndex={1}
			ref={ref}
			onBlur={e => (ref.current && !ref.current.contains(e.relatedTarget) ? onClickOutside() : null)}
			{...props}
		>
			{children}
		</div>
	)
}

export default BlurWatcher
