import React from "react"
import Navbar from "../components/Navbar"

const AdminLayout = ({ children }) => {
	return (
		<>
			<Navbar />
			<div id="container">{children}</div>
		</>
	)
}

export default AdminLayout
