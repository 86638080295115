import React, { useEffect, useState } from "react"
import { PiPencilSimpleLineFill } from "react-icons/pi"

import axios from "axios"
import Spinner, { spinnerTypes } from "../../components/Spinner"
import TableTitle from "../../components/TableTitle"
import "./styles.css"

const Commission = () => {
	const [commissions, setCommissions] = useState()
	const [loading, setLoading] = useState()
	const [editStage, setEditStage] = useState()

	useEffect(() => {
		;(async () => {
			try {
				setLoading(true)
				const response = await axios.get("/commission", {
					headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
				})
				if (response.data) setCommissions(response.data)
			} catch (error) {}
			setLoading()
		})()
	}, [])

	const updateSlab = async () => {
		setEditStage(prev => ({ ...prev, loading: true }))
		try {
			const response = await axios.put(`/commission`, editStage, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			})
			if (response.data) setCommissions(prev => prev.map(i => (i._id === response.data._id ? response.data : i)))
		} catch (error) {}
		setEditStage()
	}

	return (
		<div id="categories">
			<div className="flex row">
				<TableTitle title={"Commissions"} />
			</div>
			<div className="scrollable table-wrapper">
				<table className="nowrap">
					<thead>
						<tr className="font-md">
							<th className="left">#</th>
							<th className="left">Slab</th>
							<th className="left w-full">Commission (%)</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{commissions?.map((i, idx) => (
							<tr className="font-md">
								<td className="left">{idx + 1}.</td>
								<td className="left">
									{i.minimum_amount}-{commissions?.[idx + 1]?.minimum_amount - 1 || Infinity}
								</td>
								<td className="left w-full" colSpan={editStage?._id === i._id ? 2 : 1}>
									{editStage?._id === i._id ? (
										<div className="flex row-start gap-xl">
											<label className="theme-input w-full flex row">
												<input
													type="number"
													className="w-full"
													value={editStage?.commission}
													onChange={e => setEditStage(prev => ({ ...prev, commission: e.target.value }))}
													disabled={editStage?.loading}
												/>
												{editStage?.loading && (
													<div>
														<Spinner type={spinnerTypes.default} />
													</div>
												)}
											</label>
											<div className="flex row-start gap-m">
												<button
													className="theme-btn w-m flex secondary"
													onClick={updateSlab}
													disabled={editStage?.loading}
												>
													Save
												</button>
												<button
													className="theme-btn w-m flex danger outlined"
													onClick={() => setEditStage()}
													disabled={editStage?.loading}
												>
													Cancel
												</button>
											</div>
										</div>
									) : (
										`${i.commission}%`
									)}
								</td>
								{editStage?._id !== i._id && (
									<td>
										<div className="flex gap-m">
											<button className="edit subheading" onClick={() => setEditStage(i)}>
												<PiPencilSimpleLineFill />
											</button>
										</div>
									</td>
								)}
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{loading && <Spinner type={spinnerTypes.spinner} {...{ overlay: true }} />}
		</div>
	)
}

export default Commission
